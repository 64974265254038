<template>
    <div id="digestcontainer">
        <p v-if="getDataError" class="text-danger">Digest data kon niet opgehaald worden</p>
        <p class="renew" v-show="refreshingStaleData">Digest vernieuwen...</p>
        <p class="renew" v-show="!refreshingStaleData">Laatste update <b>{{ dataFetchTime }}</b></p>

        <table class="table">
            <thead class="">
                <tr>
                    <th :colspan="3" id="digest-heading">Digest <i class="fa fa-chevron-left" @click="digestPrev" v-show="selectedDay !== todayDay"></i> {{displaySelectedDate}} <i class="fa fa-chevron-right" @click="digestNext" v-show="!nextDisabled"></i></th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="this.loading && digested.length === 0">
                    <td colspan="3">Loading todo's</td>
                </tr>
                <tr v-else-if="!this.loading && digested.length === 0" class="no-todos">
                    <td colspan="3">No todo's for today</td>
                </tr>
                <tr v-else v-for="item in digested" :key="`${item.baseroute}-${item.id}`" :style="rowBackground(item.id, item.baseroute)">
                    <td :colspan="item.finished === 0 ? 1 : 3">
                        <i v-if="item.priority === 1" class="fa fa-star"></i>
                        <router-link :to="{ name: 'Todo', params: { type: item.baseroute, id: item.id, date: selectedDay.replaceAll('-', '') } }" title="Naar todo" exact :style="rowColor(item.id, item.baseroute)">
                            {{ `${(item.description.length > 50 ? `${item.description.substr(0,50)}...` : `${item.description.substr(0,50)}`)}` }}
                        </router-link>
                    </td>
                    <td v-if="item.finished === 0">
                        <button type="button" class="btn btn-primary" @click="finish(item.id, item.baseroute)"><i class="fa fa-flag-checkered"></i></button>
                    </td>
                    <td v-if="item.finished === 0">
                        <button type="button" class="btn btn-primary" @click="deleteItem(item.id, item.baseroute)"><i class="fa fa-trash"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { localDateStringNow } from '@/libraries/dates'
import dayjs from 'dayjs'
import 'dayjs/locale/nl'
dayjs.locale('nl') // use loaded locale globally
import { Notyf } from 'notyf'; // For error notifications
import 'notyf/notyf.min.css';

export default {
    name: 'TodoDigest',
    data() {
        return {
            loading: false,
            refreshingStaleData: false,
            getDataError: null,
            dataFetchTime: null,
            today: dayjs(),
            nextDisabled: false,
            notyf: new Notyf(),
        }
    },
    computed: {
        ...mapState('todos', ['digest', 'dataAvailable']),
        selectedDate() {
            if (this.$route.params.date) {
                return dayjs(this.$route.params.date, 'YYYY-MM-DD')
            }
            return this.today
        },
        selectedDay() {
            return this.selectedDate.format('YYYY-MM-DD')
        },
        todayDay() {
            return this.today.format('YYYY-MM-DD')
        },
        digested() {
            if (this.digest.hasOwnProperty(this.selectedDay)) {
                const digestAll = this.digest[this.selectedDay]
                .filter(todo => todo.customer_id !== 53)

                const unfinished = digestAll.filter(todo => todo.finished === 0)
                .sort(function(todo1, todo2) {
                    if (todo1.customer_id > todo2.customer_id) return 1
                    if (todo1.customer_id === todo2.customer_id) return 0
                    return -1
                })

                const finished = digestAll.filter(todo => todo.finished === 1)
                .sort(function(todo1, todo2) {
                    if (todo1.customer_id > todo2.customer_id) return 1
                    if (todo1.customer_id === todo2.customer_id) return 0
                    return -1
                })

                return unfinished.concat(finished)
            }
            return []
        },
        displaySelectedDate() {
            return dayjs(this.selectedDay).format('dddd D MMM')
        },
    },
    methods: {
        ...mapActions('todos', ['getDigest', 'finishTodo', 'deleteSingleDay', 'deleteTodo']),
        finish(id, type) {
            this.finishTodo({id, type, dateString: this.selectedDay})
            .then(() => this.notyf.success('Goed bezig!'))
            .catch(() => this.notyf.error('Afronden actiepunt niet gelukt'))
        },
        deleteItem(id, type) {
            let deletePromise
            const postData = {
                id, type, date: this.selectedDay
            }
            if (type !== 'recurring-todoitem') {
                deletePromise = this.deleteTodo(postData)
            } else {
                deletePromise = this.deleteSingleDay(postData)
            }
            deletePromise
            .then(() => this.notyf.success('En... weg is-ie!'))
            .catch(() => this.notyf.error('Verwijderen actiepunt niet gelukt'))
        },
        digestPrev() {
            this.$router.push({name: 'TodoDigest', params: {date: this.selectedDate.subtract(1, 'day').format('YYYYMMDD')}})
        },
        digestNext() {
            this.$router.push({name: 'TodoDigest', params: {date: this.selectedDate.add(1, 'day').format('YYYYMMDD')}})
        },
        rowBackground(id, type) {
            const todo = this.digested.find(item => item.id === id && item.baseroute === type)
            let height = '50px';
            if (todo.time_estimate === 1) {
                height = '80px'
            } else if (todo.time_estimate === 2) {
                height = '120px'
            } else if (todo.time_estimate === 3) {
                height = '160px'
            } else if (todo.time_estimate === 4) {
                height = '200px'
            }
            return {
                backgroundColor: todo.customer.color,
                opacity: todo.finished === 1 ? 0.4 : 1,
                height,
            }
        },
        rowColor(id, type) {
            const todo = this.digested.find(item => item.id === id && item.baseroute === type)
            return {
                color: todo.customer.textcolor,
                textDecoration: todo.finished === 1 ? 'line-through' : 'underline',
            }
        }
    },
    mounted() {
        // retrieve data with loading spinners
        // or in the background if data was already retrieved eralier on
        if (this.dataAvailable) {
            this.refreshingStaleData = true
        } else {
            this.loading = true
        }
        
        this.getDigest()
        .then(() => this.dataFetchTime = localDateStringNow()) 
        .catch(err => this.getDataError = true)
        .finally(() => {
            this.loading = false
            this.refreshingStaleData = false
        })
    },
};
</script>

<style scoped lang="less">

table {
    thead th:first-child, tbody td:first-child {
        width: 90%;
    }

    td:nth-child(n+2) {
        padding-left: 0.2rem;
        padding-right: 0.5rem;
    }

    td:last-child {
        padding-right: 0.5rem;
    }

    margin-top: 0;

    #digest-heading i.fa {
        color: #fff;
        font-size: 14px;
        position: relative;
        top: 1px;
        &.fa-chevron-left {
            margin-right: 5px;
        }
        &.fa-chevron-right {
            margin-left: 5px;
        }
    }

    tr.no-todos {
        background: @primary;
        color: white;
    }
}

.btn {
    padding: 3px 7px;
    height: 30px;
}

p.renew {
    margin-top: 6px;
    margin-bottom: 5px;
    color: #555;
    text-align: right;
    font-size: 12px;
}

#digestcontainer {
    margin-top: -24px;
}
</style>